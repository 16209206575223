import MsgTrans from '@components/common/i18n/message-trans'
import { AcademicCap } from '@components/common/icons/custom-icons'
import { FastlyImage } from '@components/common/image'
import CurriculumDownloadModal from '@components/home/curriculum-download-modal'
import { Trans, defineMessage } from '@lingui/macro'
import Link from 'next/link'
import { useState } from 'react'
import { BookOpen, Check, Clock, Download, FileText } from 'react-feather'

const COMMON_IMAGE_PATH = '/images/home/courses/card/'

const HOVER_BG = [
  'hover:bg-orange-100 hover:border-orange',
  'hover:bg-orange2-50 hover:border-orange2-400 hover:bg-opacity-75',
  'hover:bg-grey-200 hover:border-grey',
  'hover:bg-blue-50 hover:border-blue-300 hover:bg-opacity-50',
  'hover:bg-red-50 hover:border-red-200',
  'hover:bg-yellow-100 hover:bg-opacity-50 hover:border-yellow-200',
  'hover:bg-violet-50 hover:border-violet-100',
  'hover:bg-pink hover:border-pink',
]

const COURSE_PAGE_URL = {
  codingGrandMaster: '/courses/coding-grandmaster/',
  codingProdigy: '/courses/coding-prodigy/',
  codingchampion: '/courses/coding-champion/',
  scratchPrograming: '/courses/scratch-programming/',
  pythonForKids: '/courses/python-for-kids/',
  gameDevelopment: '/courses/game-development/',
  webDevelopment: '/courses/web-development/',
  appDevelopment: 'https://www.codingal.com/courses/app-development/',
  dataScience: 'https://www.codingal.com/courses/data-science/',
  roblox: 'https://www.codingal.com/courses/roblox-coding/',
  APComputerScienceA: 'https://www.codingal.com/courses/ap-computer-science-a/',
  IoiAlgorithms: 'https://www.codingal.com/courses/ioi-algorithms-champion/',
}

const COURSES = [
  {
    title: (
      <Trans>
        Coding Grandmaster:
        <br />
        Master Coding Skills
      </Trans>
    ),
    grade: '1-10',
    activites: 350,
    lessons: 144,
    duration: '12-18',
    Age: '5-15',
    description: defineMessage({
      message: `Embark on an amazing learning journey with 144 lessons for kids and teens to master coding. Choose from a pre-defined curriculum or customize your own.`,
    }),
    image: 'coding-grandmaster.png',
    altText: 'Online Coding Course for Kids & Teens to Master Coding Skills',
    outcomes: [
      defineMessage({ message: 'Learn to build apps, games & websites' }),
      defineMessage({ message: 'Mastery in all coding skills' }),
      defineMessage({ message: 'Project based learning' }),
      defineMessage({
        message: 'Coding Grandmaster certificate, accredited by STEM.org',
      }),
    ],
    courseCode: 'cgm',
    curriculumPDFLink: '',
    trialCourseQuery: 'coding',
    isBestSeller: true,
    coursePageUrl: COURSE_PAGE_URL.codingGrandMaster,
    gradeOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    title: (
      <Trans>
        Coding Prodigy:
        <br />
        Code Like a Pro
      </Trans>
    ),
    grade: '1-12',
    activites: 184,
    lessons: 96,
    duration: '9-12',
    Age: '5-17',
    description: defineMessage({
      message:
        'A 96-lesson coding curriculum designed for kids and teens to master coding skills, fostering creativity and technical proficiency.',
    }),
    image: 'coding-prodigy.png',
    altText: 'Online Coding Course for Kids & Teens to Code Like a Pro',
    outcomes: [
      defineMessage({ message: 'Learn to code like a pro' }),
      defineMessage({ message: 'Create amazing apps and games' }),
      defineMessage({
        message: 'Earn Coding Prodigy certificate (STEM.org Accredited)',
      }),
      defineMessage({ message: 'Master the art of problem solving' }),
    ],
    courseCode: 'cp',
    curriculumPDFLink: '',
    trialCourseQuery: 'coding',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.codingProdigy,
    gradeOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    title: (
      <Trans>
        Coding Champion:
        <br />
        Learn to Code
      </Trans>
    ),
    grade: '1-12',
    activites: 50,
    lessons: 48,
    duration: '4-6',
    Age: '5-17',
    description: defineMessage({
      message:
        'Kickstart coding for kids and teens with this fun course! Enjoy 48 exciting lessons and hands-on projects that spark creativity and build skills.',
    }),
    image: 'coding-champion.png',
    altText: 'Coding Course for Kids & Teens to Learn to Code',
    outcomes: [
      defineMessage({ message: 'Kickstart your coding journey' }),
      defineMessage({ message: 'Build Awesome Apps, Games & Websites' }),
      defineMessage({
        message: 'Earn Coding Champion Certificate (STEM.org Accredited)',
      }),
      defineMessage({ message: 'Unlock Problem-Solving Superpowers' }),
    ],
    courseCode: 'cc',
    curriculumPDFLink: '',
    trialCourseQuery: 'coding',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.codingchampion,
    gradeOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    title: (
      <Trans>
        Scratch Programming:
        <br />
        Unleash Your Creativity
      </Trans>
    ),
    grade: '1-8',
    activites: 50,
    lessons: 36,
    duration: '2-4',
    Age: '5-14',
    description: defineMessage({
      message:
        'Learn coding with Scratch: build games, animate, dive into AI, and unleash your creativity with endless possibilities.',
    }),
    image: 'scratch-programming.png',
    altText: 'Online Scratch Programming Course for Kids & Teens',
    outcomes: [
      defineMessage({ message: 'Learn the Coding Language' }),
      defineMessage({ message: 'Make Stuff Move (Animations & Games)' }),
      defineMessage({ message: 'Meet the Super-Smart Machines (AI Basics)' }),
      defineMessage({ message: 'Design Interactive Stories and Art' }),
    ],
    courseCode: 'sp',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/codingal-scratch-curriculum.pdf',
    trialCourseQuery: 'scratch-programming-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.scratchPrograming,
  },
  {
    title: <Trans>Game Development for Kids: Build Fun Games</Trans>,
    grade: '4-8',
    activites: 50,
    lessons: 36,
    duration: '2-4',
    Age: '8-14',
    description: defineMessage({
      message:
        'Level up your game dev skills with Scratch and Code.org! Create epic games and unleash your creativity effortlessly.',
    }),
    image: 'game-development.png',
    altText: 'Online Game Development Course for Kids to Build Fun Games',
    outcomes: [
      defineMessage({ message: 'Code Like a Pro (For Games)' }),
      defineMessage({
        message: 'Become a Game Creator (Scratch & Javascript)',
      }),
      defineMessage({ message: 'Think Like a Gamer (But Smarter)' }),
      defineMessage({
        message: 'Animate Characters and Objects (Bringing Games to Life)',
      }),
    ],
    courseCode: 'gd',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/game-development.pdf',
    trialCourseQuery: 'game-development-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.gameDevelopment,
  },
  {
    title: <Trans>App Development for Kids: Create AI-Powered Apps</Trans>,
    grade: '4-8',
    activites: 50,
    lessons: 36,
    duration: '2-4',
    Age: '8-14',
    description: defineMessage({
      message:
        'Build awesome apps using Thunkable and drop them on the Play Store. Turn your ideas into reality and go viral!',
    }),
    image: 'app-development.png',
    altText: 'Online App Development Course for Kids to Create AI-Powered Apps',
    outcomes: [
      defineMessage({ message: 'Build Interactive Interfaces' }),
      defineMessage({ message: 'Design Awesome Apps' }),
      defineMessage({
        message:
          'Make Your App Super Smart (Integrate camera, accelerometers, GPS, etc.)',
      }),
      defineMessage({ message: 'Share Your App with the World' }),
    ],
    courseCode: 'ad',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/app-development.pdf',
    trialCourseQuery: 'app-development-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.appDevelopment,
  },
  {
    title: <Trans>Roblox Champion: Build & Design Epic Games</Trans>,
    grade: '4-8',
    activites: 50,
    lessons: 36,
    duration: '2-4',
    Age: '8-14',
    description: defineMessage({
      message:
        'Create and launch Roblox games with expert tutors. Turn your ideas into viral hits!',
    }),
    image: 'roblox-champion.png',
    altText: 'Online Roblox Course for Kids to Build & Design Epic Games',
    outcomes: [
      defineMessage({ message: 'Become a Roblox Game Builder' }),
      defineMessage({ message: 'Learn Scripting with Lua' }),
      defineMessage({
        message:
          'Design Fun & Different 3D Games (tycoon simulations, racing games, and horror experiences)',
      }),
      defineMessage({ message: 'Share Your Games with the World' }),
    ],
    courseCode: 'rc',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/roblox-curriculum.pdf',
    trialCourseQuery: 'roblox-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.roblox,
  },
  {
    title: <Trans>Website Development: Learn to Build Websites</Trans>,
    grade: '8-12',
    activites: 90,
    lessons: 90,
    duration: '10-12',
    Age: '13-17',
    description: defineMessage({
      message:
        'Learn the fundamentals of the web and enhance your skills in building Interactive web pages using HTML, CSS, JavaScript, and more.',
    }),
    image: 'web-development.png',
    altText:
      'Website Development Course for Teens to Learn to Build and deploy Websites',
    outcomes: [
      defineMessage({ message: 'Build responsive websites' }),
      defineMessage({ message: 'Develop interactive web pages' }),
      defineMessage({ message: 'Master website development' }),
      defineMessage({ message: 'Implement best practices in web dev' }),
    ],
    courseCode: 'wd',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/web-development.pdf',
    trialCourseQuery: 'web-development-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.webDevelopment,
  },
  {
    title: <Trans>Python Champion: Enhance Your Skills</Trans>,
    grade: '6-12',
    activites: 90,
    lessons: 44,
    duration: '4-6',
    Age: '11-17',
    description: defineMessage({
      message:
        'Learn Python through project-based learning, exploring fundamentals, advanced libraries, OOP, game development, GUIs, and more.',
    }),
    image: 'python-champion.png',
    altText: 'Online Python Course for Teens to Enhance Coding Skills',
    outcomes: [
      defineMessage({ message: 'Master Python programming' }),
      defineMessage({ message: 'Build interactive games' }),
      defineMessage({ message: 'Develop GUI applications' }),
      defineMessage({ message: 'Understand & apply OOP concepts' }),
    ],
    courseCode: 'pc',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/codingal-python-curriculum.pdf',
    trialCourseQuery: 'python-for-kids-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.pythonForKids,
  },
  {
    title: <Trans>Data Science for Teens: Learn Python & Analytics</Trans>,
    grade: '9-12',
    activites: 200,
    lessons: 168,
    duration: '18-20',
    Age: '13-17',
    description: defineMessage({
      message:
        'Master the core principles of data science and gain practical experience through hands-on projects.',
    }),
    image: 'data-science.png',
    altText: 'Data Science Course for Teens with Python & Analytics Skills',
    outcomes: [
      defineMessage({ message: 'Master advanced libraries in Python' }),
      defineMessage({ message: 'Learn data structures and algorithms' }),
      defineMessage({ message: 'Analyze & interpret complex data sets' }),
      defineMessage({
        message: 'Implement machine learning and deep learning projects',
      }),
    ],
    courseCode: 'ds',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/data-science.pdf',
    trialCourseQuery: 'data-science-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.dataScience,
  },
  {
    title: <Trans>IOI Algorithms: Master Algorithms with Python</Trans>,
    grade: '9-12',
    activites: 150,
    lessons: 120,
    duration: '12-14',
    Age: '13-17',
    description: defineMessage({
      message:
        'Develop advanced skills in data structures and algorithms through hands-on problem-solving.',
    }),
    image: 'ioi-algorithms.png',
    altText: 'IOI Algorithms Course for Teens to Master Algorithms with Python',
    outcomes: [
      defineMessage({ message: 'Master data structures and algorithms' }),
      defineMessage({ message: 'Master problem-solving' }),
      defineMessage({ message: 'Enhance Python programming skills' }),
      defineMessage({ message: 'Prepare for coding competitions' }),
    ],
    courseCode: 'ioi',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/ioi-algorithms.pdf',
    trialCourseQuery: 'algorithms',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.IoiAlgorithms,
  },
  {
    title: <Trans>AP Computer Science A: Programming with Java</Trans>,
    grade: '11-12',
    activites: 70,
    lessons: 48,
    duration: '4-5',
    Age: '15-17',
    description: defineMessage({
      message:
        'Master Java programming, OOP, data structures, and algorithms through hands-on problem-solving.',
    }),
    image: 'ap-computer-science-a.png',
    altText:
      'AP Computer Science A Course for Teens to Learn Programming with Java',
    outcomes: [
      defineMessage({ message: 'Master Java programming' }),
      defineMessage({ message: 'Understand & apply OOP concepts' }),
      defineMessage({ message: 'Enhance algorithmic thinking' }),
      defineMessage({ message: 'Develop advanced problem-solving skills' }),
    ],
    courseCode: 'apcsa',
    curriculumPDFLink:
      'https://codingal.s3.ap-south-1.amazonaws.com/web/pdfs/curriculum/courses/v7/ap-computer-science-a.pdf',
    trialCourseQuery: 'ap-computer-science-a-trial-class',
    isBestSeller: false,
    coursePageUrl: COURSE_PAGE_URL.APComputerScienceA,
  },
]

const Courses = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [coursePDFCode, setCoursePDFCode] = useState<string | null>('cp')
  const handlePDFDownload = (courseCode: string) => {
    setCoursePDFCode(courseCode)
    setIsModalOpen(true)
  }
  const [gradeOptions, setGradeOptions] = useState<number[]>([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ])

  const getRandomHoverBg = () => {
    const randomIndex = Math.floor(Math.random() * HOVER_BG.length)
    return HOVER_BG[randomIndex]
  }

  getRandomHoverBg()

  return (
    <div className="bg-grey-100">
      <div
        className="container mx-auto flex flex-col space-y-10 leading-tight py-16 text-grey px-5"
        id="courses"
      >
        <CurriculumDownloadModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          grades={gradeOptions}
          curriculumPDFPrefix={coursePDFCode.toLowerCase()}
        />
        <div className="text-center">
          <h2 className="text-2xl lg:text-4xl font-600 mb-4">
            <Trans>
              Choose a <span className="underlined-text">coding course</span>{' '}
              that excites your child
            </Trans>
          </h2>
        </div>

        <div className="flex flex-wrap justify-center">
          {COURSES.map((course, i) => (
            <a
              href={`/register/?course=${course.trialCourseQuery}&utm_medium=homepage-course-card`}
              key={i}
              className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-md"
            >
              <div
                className={`pb-0 shadow hover:shadow-lg transition-all duration-200 rounded-md bg-white ${getRandomHoverBg()} border border-grey-100 h-full flex flex-col justify-between`}
              >
                <div className="relative w-full">
                  <div className="relative w-full">
                    {course.isBestSeller && (
                      <div className="absolute top-0 left-0 z-10 bg-orange rounded-md shadow-md px-3 py-1 text-white font-600">
                        Bestseller
                      </div>
                    )}
                    <FastlyImage
                      src={`${COMMON_IMAGE_PATH}${course.image}`}
                      alt={course.altText}
                      className="max-w-full"
                      width={530}
                      height={244}
                    />
                    <div className="absolute top-0 right-0 z-10 bg-orange-100 bg-opacity-50 rounded-sm shadow-md px-2 font-500 text-base">
                      <Trans>Age</Trans> {course.Age}
                    </div>
                  </div>

                  <div className="space-y-2 pt-3 pb-2 p-3">
                    <a
                      href={course.coursePageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3
                        className="leading-tight font-600 duration-300 cursor-pointer hover:text-orange"
                        style={{ fontSize: '1.4rem' }}
                      >
                        {course.title}
                      </h3>
                    </a>
                    <div className="flex text-sm flex-wrap">
                      <div
                        className="flex items-cente"
                        style={{ width: '102px' }}
                      >
                        <AcademicCap className="text-orange w-5" />{' '}
                        <span className="mt-1" style={{ marginLeft: '2px' }}>
                          <Trans>Grade</Trans> {course.grade}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <FileText className="text-orange w-4 mr-1" />{' '}
                        <span>
                          {course.activites}+ <Trans>Activities</Trans>
                        </span>
                      </div>
                      <div
                        className="flex items-center"
                        style={{ width: '102px' }}
                      >
                        <BookOpen
                          className="text-orange w-4 mr-1"
                          style={{ marginLeft: '2px' }}
                        />{' '}
                        <span>
                          {course.lessons} <Trans>Lessons</Trans>
                        </span>
                      </div>
                      <div className="flex items-center">
                        <Clock className="text-orange w-4 mr-1" />{' '}
                        <span>
                          {course.duration} <Trans>months</Trans>
                        </span>
                      </div>
                    </div>

                    <p className="text-sm" style={{ minHeight: '70px' }}>
                      <MsgTrans msg={course.description} />
                    </p>

                    <h4 className="font-600">
                      <Trans>Learning outcomes</Trans>
                    </h4>

                    <div className="space-y-1 text-sm">
                      {course.outcomes.map((outcome, index) => (
                        <div key={index} className="flex items-start">
                          <span
                            className="rounded-full bg-orange-200"
                            style={{ padding: '2px', marginTop: '2px' }}
                          >
                            <Check
                              height={9}
                              width={9}
                              strokeWidth={3}
                              className="text-orange"
                            />
                          </span>
                          <p className="ml-1 text-left">
                            <MsgTrans msg={outcome} />
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="relative w-full">
                  {/* If its course code is cp, cc, or cgm, then show a button with onClick event handler that opends curriculum Modal. Otherwise, show an anchor tag with the href attribute set to curriculumPDFLink, */}
                  {['cp', 'cc', 'cgm'].includes(course.courseCode) && (
                    <button
                      className="text-sm flex items-center align-middle font-600 py-2 h-10 text-orange mx-auto focus:outline-none z-20 hover:underline relative"
                      onClick={(e) => {
                        e.stopPropagation(),
                          e.preventDefault(),
                          setGradeOptions(course.gradeOptions),
                          handlePDFDownload(course.courseCode)
                      }}
                    >
                      <span className="px-1">
                        <Trans>Download curriculum</Trans>
                      </span>
                      <Download size={15} />
                    </button>
                  )}
                  {!['cp', 'cc', 'cgm'].includes(course.courseCode) && (
                    <a
                      href={course.curriculumPDFLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm flex items-center justify-center align-middle font-600 h-10 text-orange mx-auto focus:outline-none z-10 hover:underline"
                    >
                      <span className="px-1">
                        <Trans>Download curriculum</Trans>{' '}
                      </span>
                      <Download size={15} />{' '}
                    </a>
                  )}
                  <div className="px-3 mb-4">
                    <Link
                      href={`/register/?course=${course.trialCourseQuery}&utm_medium=homepage-course-card`}
                    >
                      <a className="btn border-orange text-orange hover:bg-orange hover:text-white duration-300 w-full text-center text-lg">
                        <Trans>Try a free class</Trans>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Courses
