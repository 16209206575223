import { FastlyImage } from '@components/common/image'
import Ratings from '@components/common/ratings'
import FixedBookAClassNowButton from '@components/home/fixedBookAClassNowButton'
import HeroPhoneNumber from '@components/home/hero-phone-numer'
import Partners from '@components/locale-pages/home/us/partners'
import PhoneInputContainer from '@containers/phone-input'
import useDetectCountry from '@hooks/useDetectCountry'
import { t, Trans } from '@lingui/macro'
import { useEffect, useRef, useState } from 'react'
import PriceSlotsOverview from './price-slots-overview'
import StudentCountriesOverview from './student-countries-overview'
import { RAMADAN_COUNTRY_CODES } from '@lib/data/country'
import BackgroundImageComponent from '@components/common/background-image'

const RAMADAN_FOLD_IMAGE_PATH = `/images/ramadan-coding-camp/first-fold/`

const IMAGE_PATH = '/images/home/first-fold/'
const IMAGE_PROPS = {
  PK: {
    path: `${IMAGE_PATH}hero-banner-PK.png`,
    sizes: {
      height: 400,
      width: 600,
    },
  },
  BD: {
    path: `${IMAGE_PATH}hero-banner-BD.png`,
    sizes: {
      height: 400,
      width: 630,
    },
  },
  NG: {
    path: `${IMAGE_PATH}hero-banner-NG.png`,
    sizes: {
      height: 400,
      width: 630,
    },
  },
  UG: {
    path: `${IMAGE_PATH}hero-banner-UG.png`,
    sizes: {
      height: 400,
      width: 630,
    },
  },
  NP: {
    path: `${IMAGE_PATH}hero-banner-NP.png`,
    sizes: {
      height: 400,
      width: 630,
    },
  },
  US: {
    path: `${IMAGE_PATH}hero-banner-US.png`,
    sizes: {
      height: 500,
      width: 760,
    },
  },
  CA: {
    path: `${IMAGE_PATH}hero-banner-US.png`,
    sizes: {
      height: 500,
      width: 760,
    },
  },
  default: {
    path: `${IMAGE_PATH}hero-banner-IN.png`,
    sizes: {
      height: 400,
      width: 600,
    },
  },
}

type Props = {
  localeCountryCode?: string
}
/** i18n support added */
const FirstFold = ({ localeCountryCode }: Props) => {
  const [isFixedMobileCTAVisible, setIsFixedMobileCTAVisible] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const { countryCode } = useDetectCountry()
  const isCountryCodeFinalized = localeCountryCode || countryCode

  const heroImage =
    IMAGE_PROPS[localeCountryCode || countryCode] || IMAGE_PROPS['default']

  const isRamadanCountry = RAMADAN_COUNTRY_CODES.includes(countryCode)
  const ramadanCountry = {
    src: `${RAMADAN_FOLD_IMAGE_PATH}hero-banner.png`,
    width: 576,
    height: 379,
    alt: 'Ramadan coding class for kids background image',
  }

  const showFixedCTAOnScroll = (event: any) => {
    if (!containerRef.current) return
    const { offsetTop, offsetHeight } = containerRef.current
    const scrollTopPosition = event.target.documentElement.scrollTop
    setIsFixedMobileCTAVisible(offsetTop + offsetHeight < scrollTopPosition)
  }

  useEffect(() => {
    // Auto-focus on phone input field on page load
    document.getElementById('phone').focus()

    // Show fixed CTA upon scrolling on mobile view
    window.addEventListener('scroll', showFixedCTAOnScroll)
    return () => window.removeEventListener('scroll', showFixedCTAOnScroll)
  }, [])

  return (
    <>
      {isFixedMobileCTAVisible && <FixedBookAClassNowButton />}
      <div
        className="relative flex justify-between px-5 md:px-3 pt-8 pb-10 overflow-visible lg:pb-20 lg:rounded-b-lg hero-gradient-red-purple"
        ref={containerRef}
      >
        {isRamadanCountry && <BackgroundImage />}
        <div className="relaive container flex flex-col mx-auto text-white z-10 lg:flex-row">
          <div className="z-10 flex flex-col items-center space-y-5 text-center lg:items-start lg:text-left lg:mt-10">
            <Ratings />
            <h1 className="max-w-2xl text-2xl leading-tight text-center font-600 lg:text-5xl lg:text-left">
              <Trans>
                Online coding classes for kids & teens to become the{' '}
                <span className="underlined-text">innovators</span> of the{' '}
                <span className="underlined-text">future</span>
              </Trans>
            </h1>
            {/* phone number input */}
            <div className="text-left">
              <PhoneInputContainer
                buttonText={t`Try a free class`}
                isInHomePage
                countryCode={localeCountryCode}
              />
              <div className="mt-3">
                <PriceSlotsOverview />
              </div>
            </div>
            <StudentCountriesOverview />

            <div>
              <HeroPhoneNumber />
            </div>
          </div>
          <div className="relative flex flex-col items-center justify-center mx-auto">
            {isCountryCodeFinalized &&
              (isRamadanCountry ? (
                <FastlyImage
                  src={ramadanCountry.src}
                  width={ramadanCountry.width}
                  height={ramadanCountry.height}
                  alt={ramadanCountry.alt}
                  priority
                />
              ) : (
                <FastlyImage
                  src={heroImage.path}
                  width={heroImage.sizes.width}
                  height={heroImage.sizes.height}
                  alt={t`Children learning to code through online coding classes`}
                  priority
                />
              ))}
          </div>
        </div>
      </div>
      <div className="relative">
        <Partners />
      </div>
    </>
  )
}
const BackgroundImage = (): JSX.Element => (
  <>
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}bg.png`}
      top={0}
      left={0}
      zIndex={0}
      className="block w-full h-full opacity-50"
      alt="Ramadan coding class for kids background image"
    />
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}mosque3.png`}
      top="unset"
      bottom={0}
      left={0}
      zIndex={1}
      className="block w-full "
    />
    <div className="hidden lg:block">
      <BackgroundImageComponent
        image={`${RAMADAN_FOLD_IMAGE_PATH}lamp.png`}
        top={0}
        left={0}
        zIndex={3}
        className="hidden xl:block"
      />
    </div>
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}star-lamp1.png`}
      top={0}
      right={2}
      zIndex={3}
      className="hidden lg:block"
    />
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}stars.png`}
      top={0}
      left={50}
      zIndex={3}
      className="hidden lg:block"
    />
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}stars.png`}
      top={5}
      right={0}
      zIndex={3}
      className="block lg:hidden"
    />
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}moon.png`}
      top={0}
      right={8}
      zIndex={3}
      className="hidden lg:block"
    />
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}moon.png`}
      top={60}
      right={0}
      zIndex={3}
      className="block lg:hidden"
    />
    <BackgroundImageComponent
      image={`${RAMADAN_FOLD_IMAGE_PATH}star.png`}
      top={10}
      right={50}
      zIndex={3}
      className="hidden lg:block"
    />
  </>
)

export default FirstFold
