import Footer from '@components/common/footer'
import I18nNextSeo from '@components/common/i18n/i18n-next-seo'
import NavBar from '@components/common/navbar'
import Articles from '@components/home/articles'
import BookFreeClassFooterBanner from '@components/home/book-free-class-footer-banner'
import CodingalOffering from '@components/home/codingal-offering'
import CompetitionsFold from '@components/home/competitions-fold'
import Faq from '@components/home/faq'
import FirstFold from '@components/home/first-fold'
import Founders from '@components/home/founders'
import MoneyBackGuarantee from '@components/home/guarantee'
import LearningDashboard from '@components/home/learning-dashboard'
import Pricings from '@components/home/pricings'
import StudentProjectsFold from '@components/home/student-projects-fold'
import Teachers from '@components/home/teachers'
import TrustedPlatform from '@components/home/trusted-platform'
import WhyLearnToCode from '@components/home/why-learn-to-code'
import { FAQ_CONTENTS } from '@lib/data/faq'
import { homePageSeo } from '@lib/seo'
import { FAQPageJsonLd } from 'next-seo'
import Courses from '@components/locale-pages/home/courses'
import VideoTestimonial from '@components/locale-pages/home/video-testimonial'
import { VIDEO_TESTIMONIAL } from '@lib/data/summer-coding-camp'
import TrustpilotReview from '@components/locale-pages/home/trustpilot-review'
import { Trans } from '@lingui/macro'
import useDetectCountry from '@hooks/useDetectCountry'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const DefaultHome = () => {
  const { countryCode } = useDetectCountry()
  const router = useRouter()

  const [localCountryCode, setLocalCountryCode] = useState(countryCode)

  useEffect(() => {
    const locale = router?.locale

    if (locale === 'en-PK') {
      setLocalCountryCode('PK')
    } else if (locale === 'en-US') {
      setLocalCountryCode('US')
    } else if (locale === 'en-CA') {
      setLocalCountryCode('CA')
    }
  }, [router?.locale])

  return (
    <>
      <I18nNextSeo {...homePageSeo} />
      <FAQPageJsonLd
        mainEntity={FAQ_CONTENTS.map(
          ({ acceptedAnswerTextString, questionName }) => ({
            acceptedAnswerText: acceptedAnswerTextString,
            questionName: questionName,
          })
        )}
      />
      <div data-testid="home-page">
        <NavBar isSticky />
        <FirstFold localeCountryCode={localCountryCode} />
        <Courses />
        <div style={{ backgroundColor: '#F4FAFF' }}>
          <VideoTestimonial
            title={<Trans>Students and parents love Codingal</Trans>}
            video_testimonial={VIDEO_TESTIMONIAL}
          />
          <TrustpilotReview shouldScrollToTop />
        </div>
        <Teachers />
        <MoneyBackGuarantee />
        <Pricings />
        <CodingalOffering />
        <WhyLearnToCode />
        <StudentProjectsFold />
        <LearningDashboard />
        <div className="bg-blue-100 bg-opacity-25">
          <CompetitionsFold shouldShowPartnerLogos />
        </div>
        <Founders />
        <div className="bg-orange-100">
          <TrustedPlatform />
        </div>
        <Articles />
        <Faq />
        <div className="-mb-10">
          <BookFreeClassFooterBanner />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default DefaultHome
